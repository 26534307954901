<template>
  <div class="wrap__content">
    <div class="content__page">
      <div class="section__subsidizing--banner">
        <div class="container">
          <div class="subsidizing__banner subsidizing__banner--yellow">
            <div class="subsidizing__banner--left">
              <div class="content__page--back">
                <a @click="$router.go(-1)">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 12H5"
                      stroke="#0E1839"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 19L5 12L12 5"
                      stroke="#0E1839"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {{ $t('system.back') }}
                </a>
              </div>
              <div
                class="subsidizing__banner--title section__title"
                v-html="$t('subsidizing_foreign.title')"
              ></div>
              <div class="subsidizing__banner--desc">
                {{ $t('subsidizing_foreign.desc') }}
              </div>
              <div class="subsidizing__banner--download">
                <a
                  v-if="$i18n.locale == 'kz'"
                  href="/download/regulations-kz.pdf"
                  download=""
                  >{{ $t('system.download_rules') }}</a
                >
                <a v-else href="/download/regulations.pdf" download="">{{
                  $t('system.download_rules')
                }}</a>
              </div>
              <div class="subsidizing__banner--button section--link">
                <a :href="'/' + $i18n.locale + '/registration'">{{
                  $t('system.apply')
                }}</a>
                <a
                  :href="'/' + $i18n.locale + '/registration'"
                  class="link--active"
                  >{{ $t('system.my_statements') }}</a
                >
              </div>
            </div>
            <div class="subsidizing__banner--right">
              <img src="../assets/img/subsidizing__banner-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="section__stage--subs">
        <div class="stage__subs--item stage__subs--default">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_1.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_foreign.stage.stage_1.desc')"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--purple">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_2.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_foreign.stage.stage_2.desc')"
                ></div>

                <swiper
                  class="stage__subs--conditions stage__subs--slider"
                  :options="conditionsOption"
                >
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg
                          width="74"
                          height="74"
                          viewBox="0 0 74 74"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M57.668 65.0391H16.6211C14.3602 65.0368 12.1926 64.1376 10.5939 62.5389C8.9952 60.9402 8.09605 58.7726 8.09375 56.5117V35.5547C8.09605 33.2938 8.9952 31.1262 10.5939 29.5275C12.1926 27.9288 14.3602 27.0296 16.6211 27.0273H31.054C32.1656 27.0273 33.2317 26.5858 34.0178 25.7997C34.8038 25.0137 35.2454 23.9476 35.2454 22.8359C35.2477 20.575 36.1468 18.4074 37.7455 16.8087C39.3442 15.21 41.5118 14.3109 43.7727 14.3086H57.668C59.9289 14.3109 62.0965 15.21 63.6952 16.8087C65.2939 18.4074 66.193 20.575 66.1953 22.8359V56.5117C66.1926 58.7725 65.2934 60.9399 63.6948 62.5385C62.0961 64.1371 59.9287 65.0364 57.668 65.0391V65.0391ZM16.6211 31.3633C15.5095 31.3633 14.4434 31.8049 13.6573 32.5909C12.8713 33.377 12.4297 34.4431 12.4297 35.5547V56.5117C12.4297 57.6233 12.8713 58.6895 13.6573 59.4755C14.4434 60.2615 15.5095 60.7031 16.6211 60.7031H57.668C58.7796 60.7031 59.8457 60.2615 60.6317 59.4755C61.4178 58.6895 61.8594 57.6233 61.8594 56.5117V22.8359C61.8594 21.7243 61.4178 20.6582 60.6317 19.8722C59.8457 19.0861 58.7796 18.6445 57.668 18.6445H43.7727C42.6611 18.6445 41.595 19.0861 40.809 19.8722C40.0229 20.6582 39.5813 21.7243 39.5813 22.8359C39.5786 25.0967 38.6794 27.2641 37.0808 28.8627C35.4822 30.4613 33.3148 31.3606 31.054 31.3633H16.6211Z"
                            fill="#FFCE03"
                          />
                          <path
                            d="M17.4883 31.3633C16.9133 31.3633 16.3619 31.1349 15.9553 30.7283C15.5487 30.3217 15.3203 29.7703 15.3203 29.1953V9.10547C15.3203 8.53049 15.5487 7.97906 15.9553 7.57248C16.3619 7.16591 16.9133 6.9375 17.4883 6.9375H56.8008C57.3758 6.9375 57.9272 7.16591 58.3338 7.57248C58.7403 7.97906 58.9688 8.53049 58.9688 9.10547V16.4766C58.9688 17.0515 58.7403 17.603 58.3338 18.0095C57.9272 18.4161 57.3758 18.6445 56.8008 18.6445C56.2258 18.6445 55.6744 18.4161 55.2678 18.0095C54.8612 17.603 54.6328 17.0515 54.6328 16.4766V11.2734H19.6562V29.1953C19.6562 29.7703 19.4278 30.3217 19.0213 30.7283C18.6147 31.1349 18.0633 31.3633 17.4883 31.3633Z"
                            fill="#FFCE03"
                          />
                        </svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_1') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg
                          width="74"
                          height="74"
                          viewBox="0 0 74 74"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M57.668 65.0391H16.6211C14.3602 65.0368 12.1926 64.1376 10.5939 62.5389C8.9952 60.9402 8.09605 58.7726 8.09375 56.5117V35.5547C8.09605 33.2938 8.9952 31.1262 10.5939 29.5275C12.1926 27.9288 14.3602 27.0296 16.6211 27.0273H31.054C32.1656 27.0273 33.2317 26.5858 34.0178 25.7997C34.8038 25.0137 35.2454 23.9476 35.2454 22.8359C35.2477 20.575 36.1468 18.4074 37.7455 16.8087C39.3442 15.21 41.5118 14.3109 43.7727 14.3086H57.668C59.9289 14.3109 62.0965 15.21 63.6952 16.8087C65.2939 18.4074 66.193 20.575 66.1953 22.8359V56.5117C66.1926 58.7725 65.2934 60.9399 63.6948 62.5385C62.0961 64.1371 59.9287 65.0364 57.668 65.0391V65.0391ZM16.6211 31.3633C15.5095 31.3633 14.4434 31.8049 13.6573 32.5909C12.8713 33.377 12.4297 34.4431 12.4297 35.5547V56.5117C12.4297 57.6233 12.8713 58.6895 13.6573 59.4755C14.4434 60.2615 15.5095 60.7031 16.6211 60.7031H57.668C58.7796 60.7031 59.8457 60.2615 60.6317 59.4755C61.4178 58.6895 61.8594 57.6233 61.8594 56.5117V22.8359C61.8594 21.7243 61.4178 20.6582 60.6317 19.8722C59.8457 19.0861 58.7796 18.6445 57.668 18.6445H43.7727C42.6611 18.6445 41.595 19.0861 40.809 19.8722C40.0229 20.6582 39.5813 21.7243 39.5813 22.8359C39.5786 25.0967 38.6794 27.2641 37.0808 28.8627C35.4822 30.4613 33.3148 31.3606 31.054 31.3633H16.6211Z"
                            fill="#FFCE03"
                          />
                          <path
                            d="M17.4883 31.3633C16.9133 31.3633 16.3619 31.1349 15.9553 30.7283C15.5487 30.3217 15.3203 29.7703 15.3203 29.1953V9.10547C15.3203 8.53049 15.5487 7.97906 15.9553 7.57248C16.3619 7.16591 16.9133 6.9375 17.4883 6.9375H56.8008C57.3758 6.9375 57.9272 7.16591 58.3338 7.57248C58.7403 7.97906 58.9688 8.53049 58.9688 9.10547V16.4766C58.9688 17.0515 58.7403 17.603 58.3338 18.0095C57.9272 18.4161 57.3758 18.6445 56.8008 18.6445C56.2258 18.6445 55.6744 18.4161 55.2678 18.0095C54.8612 17.603 54.6328 17.0515 54.6328 16.4766V11.2734H19.6562V29.1953C19.6562 29.7703 19.4278 30.3217 19.0213 30.7283C18.6147 31.1349 18.0633 31.3633 17.4883 31.3633Z"
                            fill="#FFCE03"
                          />
                        </svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_2') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg
                          width="74"
                          height="74"
                          viewBox="0 0 74 74"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M57.668 65.0391H16.6211C14.3602 65.0368 12.1926 64.1376 10.5939 62.5389C8.9952 60.9402 8.09605 58.7726 8.09375 56.5117V35.5547C8.09605 33.2938 8.9952 31.1262 10.5939 29.5275C12.1926 27.9288 14.3602 27.0296 16.6211 27.0273H31.054C32.1656 27.0273 33.2317 26.5858 34.0178 25.7997C34.8038 25.0137 35.2454 23.9476 35.2454 22.8359C35.2477 20.575 36.1468 18.4074 37.7455 16.8087C39.3442 15.21 41.5118 14.3109 43.7727 14.3086H57.668C59.9289 14.3109 62.0965 15.21 63.6952 16.8087C65.2939 18.4074 66.193 20.575 66.1953 22.8359V56.5117C66.1926 58.7725 65.2934 60.9399 63.6948 62.5385C62.0961 64.1371 59.9287 65.0364 57.668 65.0391V65.0391ZM16.6211 31.3633C15.5095 31.3633 14.4434 31.8049 13.6573 32.5909C12.8713 33.377 12.4297 34.4431 12.4297 35.5547V56.5117C12.4297 57.6233 12.8713 58.6895 13.6573 59.4755C14.4434 60.2615 15.5095 60.7031 16.6211 60.7031H57.668C58.7796 60.7031 59.8457 60.2615 60.6317 59.4755C61.4178 58.6895 61.8594 57.6233 61.8594 56.5117V22.8359C61.8594 21.7243 61.4178 20.6582 60.6317 19.8722C59.8457 19.0861 58.7796 18.6445 57.668 18.6445H43.7727C42.6611 18.6445 41.595 19.0861 40.809 19.8722C40.0229 20.6582 39.5813 21.7243 39.5813 22.8359C39.5786 25.0967 38.6794 27.2641 37.0808 28.8627C35.4822 30.4613 33.3148 31.3606 31.054 31.3633H16.6211Z"
                            fill="#FFCE03"
                          />
                          <path
                            d="M17.4883 31.3633C16.9133 31.3633 16.3619 31.1349 15.9553 30.7283C15.5487 30.3217 15.3203 29.7703 15.3203 29.1953V9.10547C15.3203 8.53049 15.5487 7.97906 15.9553 7.57248C16.3619 7.16591 16.9133 6.9375 17.4883 6.9375H56.8008C57.3758 6.9375 57.9272 7.16591 58.3338 7.57248C58.7403 7.97906 58.9688 8.53049 58.9688 9.10547V16.4766C58.9688 17.0515 58.7403 17.603 58.3338 18.0095C57.9272 18.4161 57.3758 18.6445 56.8008 18.6445C56.2258 18.6445 55.6744 18.4161 55.2678 18.0095C54.8612 17.603 54.6328 17.0515 54.6328 16.4766V11.2734H19.6562V29.1953C19.6562 29.7703 19.4278 30.3217 19.0213 30.7283C18.6147 31.1349 18.0633 31.3633 17.4883 31.3633Z"
                            fill="#FFCE03"
                          />
                        </svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_3') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg
                          width="74"
                          height="74"
                          viewBox="0 0 74 74"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M57.668 65.0391H16.6211C14.3602 65.0368 12.1926 64.1376 10.5939 62.5389C8.9952 60.9402 8.09605 58.7726 8.09375 56.5117V35.5547C8.09605 33.2938 8.9952 31.1262 10.5939 29.5275C12.1926 27.9288 14.3602 27.0296 16.6211 27.0273H31.054C32.1656 27.0273 33.2317 26.5858 34.0178 25.7997C34.8038 25.0137 35.2454 23.9476 35.2454 22.8359C35.2477 20.575 36.1468 18.4074 37.7455 16.8087C39.3442 15.21 41.5118 14.3109 43.7727 14.3086H57.668C59.9289 14.3109 62.0965 15.21 63.6952 16.8087C65.2939 18.4074 66.193 20.575 66.1953 22.8359V56.5117C66.1926 58.7725 65.2934 60.9399 63.6948 62.5385C62.0961 64.1371 59.9287 65.0364 57.668 65.0391V65.0391ZM16.6211 31.3633C15.5095 31.3633 14.4434 31.8049 13.6573 32.5909C12.8713 33.377 12.4297 34.4431 12.4297 35.5547V56.5117C12.4297 57.6233 12.8713 58.6895 13.6573 59.4755C14.4434 60.2615 15.5095 60.7031 16.6211 60.7031H57.668C58.7796 60.7031 59.8457 60.2615 60.6317 59.4755C61.4178 58.6895 61.8594 57.6233 61.8594 56.5117V22.8359C61.8594 21.7243 61.4178 20.6582 60.6317 19.8722C59.8457 19.0861 58.7796 18.6445 57.668 18.6445H43.7727C42.6611 18.6445 41.595 19.0861 40.809 19.8722C40.0229 20.6582 39.5813 21.7243 39.5813 22.8359C39.5786 25.0967 38.6794 27.2641 37.0808 28.8627C35.4822 30.4613 33.3148 31.3606 31.054 31.3633H16.6211Z"
                            fill="#FFCE03"
                          />
                          <path
                            d="M17.4883 31.3633C16.9133 31.3633 16.3619 31.1349 15.9553 30.7283C15.5487 30.3217 15.3203 29.7703 15.3203 29.1953V9.10547C15.3203 8.53049 15.5487 7.97906 15.9553 7.57248C16.3619 7.16591 16.9133 6.9375 17.4883 6.9375H56.8008C57.3758 6.9375 57.9272 7.16591 58.3338 7.57248C58.7403 7.97906 58.9688 8.53049 58.9688 9.10547V16.4766C58.9688 17.0515 58.7403 17.603 58.3338 18.0095C57.9272 18.4161 57.3758 18.6445 56.8008 18.6445C56.2258 18.6445 55.6744 18.4161 55.2678 18.0095C54.8612 17.603 54.6328 17.0515 54.6328 16.4766V11.2734H19.6562V29.1953C19.6562 29.7703 19.4278 30.3217 19.0213 30.7283C18.6147 31.1349 18.0633 31.3633 17.4883 31.3633Z"
                            fill="#FFCE03"
                          />
                        </svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_4') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg
                          width="74"
                          height="74"
                          viewBox="0 0 74 74"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M57.668 65.0391H16.6211C14.3602 65.0368 12.1926 64.1376 10.5939 62.5389C8.9952 60.9402 8.09605 58.7726 8.09375 56.5117V35.5547C8.09605 33.2938 8.9952 31.1262 10.5939 29.5275C12.1926 27.9288 14.3602 27.0296 16.6211 27.0273H31.054C32.1656 27.0273 33.2317 26.5858 34.0178 25.7997C34.8038 25.0137 35.2454 23.9476 35.2454 22.8359C35.2477 20.575 36.1468 18.4074 37.7455 16.8087C39.3442 15.21 41.5118 14.3109 43.7727 14.3086H57.668C59.9289 14.3109 62.0965 15.21 63.6952 16.8087C65.2939 18.4074 66.193 20.575 66.1953 22.8359V56.5117C66.1926 58.7725 65.2934 60.9399 63.6948 62.5385C62.0961 64.1371 59.9287 65.0364 57.668 65.0391V65.0391ZM16.6211 31.3633C15.5095 31.3633 14.4434 31.8049 13.6573 32.5909C12.8713 33.377 12.4297 34.4431 12.4297 35.5547V56.5117C12.4297 57.6233 12.8713 58.6895 13.6573 59.4755C14.4434 60.2615 15.5095 60.7031 16.6211 60.7031H57.668C58.7796 60.7031 59.8457 60.2615 60.6317 59.4755C61.4178 58.6895 61.8594 57.6233 61.8594 56.5117V22.8359C61.8594 21.7243 61.4178 20.6582 60.6317 19.8722C59.8457 19.0861 58.7796 18.6445 57.668 18.6445H43.7727C42.6611 18.6445 41.595 19.0861 40.809 19.8722C40.0229 20.6582 39.5813 21.7243 39.5813 22.8359C39.5786 25.0967 38.6794 27.2641 37.0808 28.8627C35.4822 30.4613 33.3148 31.3606 31.054 31.3633H16.6211Z"
                            fill="#FFCE03"
                          />
                          <path
                            d="M17.4883 31.3633C16.9133 31.3633 16.3619 31.1349 15.9553 30.7283C15.5487 30.3217 15.3203 29.7703 15.3203 29.1953V9.10547C15.3203 8.53049 15.5487 7.97906 15.9553 7.57248C16.3619 7.16591 16.9133 6.9375 17.4883 6.9375H56.8008C57.3758 6.9375 57.9272 7.16591 58.3338 7.57248C58.7403 7.97906 58.9688 8.53049 58.9688 9.10547V16.4766C58.9688 17.0515 58.7403 17.603 58.3338 18.0095C57.9272 18.4161 57.3758 18.6445 56.8008 18.6445C56.2258 18.6445 55.6744 18.4161 55.2678 18.0095C54.8612 17.603 54.6328 17.0515 54.6328 16.4766V11.2734H19.6562V29.1953C19.6562 29.7703 19.4278 30.3217 19.0213 30.7283C18.6147 31.1349 18.0633 31.3633 17.4883 31.3633Z"
                            fill="#FFCE03"
                          />
                        </svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_5') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg
                          width="74"
                          height="74"
                          viewBox="0 0 74 74"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M57.668 65.0391H16.6211C14.3602 65.0368 12.1926 64.1376 10.5939 62.5389C8.9952 60.9402 8.09605 58.7726 8.09375 56.5117V35.5547C8.09605 33.2938 8.9952 31.1262 10.5939 29.5275C12.1926 27.9288 14.3602 27.0296 16.6211 27.0273H31.054C32.1656 27.0273 33.2317 26.5858 34.0178 25.7997C34.8038 25.0137 35.2454 23.9476 35.2454 22.8359C35.2477 20.575 36.1468 18.4074 37.7455 16.8087C39.3442 15.21 41.5118 14.3109 43.7727 14.3086H57.668C59.9289 14.3109 62.0965 15.21 63.6952 16.8087C65.2939 18.4074 66.193 20.575 66.1953 22.8359V56.5117C66.1926 58.7725 65.2934 60.9399 63.6948 62.5385C62.0961 64.1371 59.9287 65.0364 57.668 65.0391V65.0391ZM16.6211 31.3633C15.5095 31.3633 14.4434 31.8049 13.6573 32.5909C12.8713 33.377 12.4297 34.4431 12.4297 35.5547V56.5117C12.4297 57.6233 12.8713 58.6895 13.6573 59.4755C14.4434 60.2615 15.5095 60.7031 16.6211 60.7031H57.668C58.7796 60.7031 59.8457 60.2615 60.6317 59.4755C61.4178 58.6895 61.8594 57.6233 61.8594 56.5117V22.8359C61.8594 21.7243 61.4178 20.6582 60.6317 19.8722C59.8457 19.0861 58.7796 18.6445 57.668 18.6445H43.7727C42.6611 18.6445 41.595 19.0861 40.809 19.8722C40.0229 20.6582 39.5813 21.7243 39.5813 22.8359C39.5786 25.0967 38.6794 27.2641 37.0808 28.8627C35.4822 30.4613 33.3148 31.3606 31.054 31.3633H16.6211Z"
                            fill="#FFCE03"
                          />
                          <path
                            d="M17.4883 31.3633C16.9133 31.3633 16.3619 31.1349 15.9553 30.7283C15.5487 30.3217 15.3203 29.7703 15.3203 29.1953V9.10547C15.3203 8.53049 15.5487 7.97906 15.9553 7.57248C16.3619 7.16591 16.9133 6.9375 17.4883 6.9375H56.8008C57.3758 6.9375 57.9272 7.16591 58.3338 7.57248C58.7403 7.97906 58.9688 8.53049 58.9688 9.10547V16.4766C58.9688 17.0515 58.7403 17.603 58.3338 18.0095C57.9272 18.4161 57.3758 18.6445 56.8008 18.6445C56.2258 18.6445 55.6744 18.4161 55.2678 18.0095C54.8612 17.603 54.6328 17.0515 54.6328 16.4766V11.2734H19.6562V29.1953C19.6562 29.7703 19.4278 30.3217 19.0213 30.7283C18.6147 31.1349 18.0633 31.3633 17.4883 31.3633Z"
                            fill="#FFCE03"
                          />
                        </svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_6') }}
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="conditions--arrows">
                  <div
                    class="conditions__arrow conditions__arrow--prev"
                    slot="button-prev"
                  >
                    <svg
                      width="74"
                      height="24"
                      viewBox="0 0 74 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M76 12L5 12"
                        stroke="#DCDCF0"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 19L5 12L12 5"
                        stroke="#DCDCF0"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    class="conditions__arrow conditions__arrow--next"
                    slot="button-next"
                  >
                    <svg
                      width="74"
                      height="24"
                      viewBox="0 0 74 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-2 12H69"
                        stroke="#FFCE03"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M62 5L69 12L62 19"
                        stroke="#FFCE03"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--error">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_3.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_foreign.stage.stage_3.desc')"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--default">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_4.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div class="d-none d-xl-block">
                  <div class="obtaining--slider">
                    <div class="stage__subs--obtaining">
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-1.png"
                            />
                            <span>
                              <svg
                                width="57"
                                height="113"
                                viewBox="0 0 57 113"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                  stroke="#FFCE03"
                                  stroke-width="4"
                                />
                              </svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_1.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_1.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-2.png"
                            />
                            <span>
                              <svg
                                width="57"
                                height="113"
                                viewBox="0 0 57 113"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                  stroke="#FFCE03"
                                  stroke-width="4"
                                />
                              </svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_2.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_2.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-3.png"
                            />
                            <span>
                              <svg
                                width="57"
                                height="113"
                                viewBox="0 0 57 113"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                  stroke="#FFCE03"
                                  stroke-width="4"
                                />
                              </svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_3.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_3.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-4.png"
                            />
                            <span>
                              <svg
                                width="57"
                                height="113"
                                viewBox="0 0 57 113"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                  stroke="#FFCE03"
                                  stroke-width="4"
                                />
                              </svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_4.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_4.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-5.png"
                            />
                            <span>
                              <svg
                                width="57"
                                height="113"
                                viewBox="0 0 57 113"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                  stroke="#FFCE03"
                                  stroke-width="4"
                                />
                              </svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_5.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_5.text',
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-block d-xl-none">
                  <swiper class="obtaining--slider" :options="obtainingOption">
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-1.png"
                          />
                          <span>
                            <svg
                              width="57"
                              height="113"
                              viewBox="0 0 57 113"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                stroke="#FFCE03"
                                stroke-width="4"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_1.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_1.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-2.png"
                          />
                          <span>
                            <svg
                              width="57"
                              height="113"
                              viewBox="0 0 57 113"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                stroke="#FFCE03"
                                stroke-width="4"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_2.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_2.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-3.png"
                          />
                          <span>
                            <svg
                              width="57"
                              height="113"
                              viewBox="0 0 57 113"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                stroke="#FFCE03"
                                stroke-width="4"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_3.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_3.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-4.png"
                          />
                          <span>
                            <svg
                              width="57"
                              height="113"
                              viewBox="0 0 57 113"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                stroke="#FFCE03"
                                stroke-width="4"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_4.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_4.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-5.png"
                          />
                          <span>
                            <svg
                              width="57"
                              height="113"
                              viewBox="0 0 57 113"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M56.5 111C42.0457 111 28.1834 105.258 17.9627 95.0373C7.74195 84.8166 2 70.9543 2 56.5C2 42.0457 7.74195 28.1834 17.9627 17.9627C28.1834 7.74195 42.0457 2 56.5 2"
                                stroke="#FFCE03"
                                stroke-width="4"
                              />
                            </svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_5.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_5.text')
                        }}
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="conditions--arrows">
                    <div
                      class="conditions__arrow obtaining__arrow--prev"
                      slot="button-prev"
                    >
                      <svg
                        width="74"
                        height="24"
                        viewBox="0 0 74 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M76 12L5 12"
                          stroke="#DCDCF0"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 19L5 12L12 5"
                          stroke="#DCDCF0"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      class="conditions__arrow obtaining__arrow--next"
                      slot="button-next"
                    >
                      <svg
                        width="74"
                        height="24"
                        viewBox="0 0 74 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-2 12H69"
                          stroke="#FFCE03"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M62 5L69 12L62 19"
                          stroke="#FFCE03"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
  name: 'SubsidingForeign',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      conditionsOption: {
        slidesPerView: 2,
        spaceBetween: 5,
        navigation: {
          prevEl: '.conditions__arrow--prev',
          nextEl: '.conditions__arrow--next',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          520: {
            slidesPerView: 1,
          },
          992: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 2,
          },
        },
      },
      obtainingOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
          prevEl: '.obtaining__arrow--prev',
          nextEl: '.obtaining__arrow--next',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          520: {
            slidesPerView: 1.2,
          },
          645: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
  head: {
    title() {
      return {
        inner:
          this.$i18n.locale == 'kz'
            ? 'Шетелдік турист үшін субсидиялау | «eQonaq» мамандандырылған ақпараттық жүйесі'
            : this.$i18n.locale == 'ru'
              ? 'Субсидирование за иностранного туриста | Специализированная информационная система «eQonaq»'
              : 'Subsidizing for a foreign tourist  | Specialized information system "eQonaq"',
      }
    },
    meta: [{ name: 'description', content: 'My description' }],
  },
}
</script>

<style scoped></style>
